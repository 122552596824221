import { Component, Prop, Vue } from 'vue-property-decorator'
import { ActionType } from '@/modules/common/enums/action-types.enum'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'AuthnetForm'
})

export default class AuthnetForm extends Vue {
  @Prop({ required: true, type: Number, default: 0 })
  lrordersTransaction: number | undefined

  @Prop({ required: true, type: Array, default: [] })
  itemsToAdd: Array<any> | undefined

  @Prop({ required: true, type: String, default: '' })
  transactionUuid: string | undefined

  @Prop({ required: true, type: String, default: '' })
  widgetUuid: string | undefined

  @Prop({ required: true, type: String, default: '' })
  paymentType: string | undefined

  @Prop({ required: true, type: String, default: '' })
  eventUuid: string | undefined

  @Prop({ required: true, type: String, default: '' })
  participantUuid: string | undefined

  @Prop({ required: true, type: Object, default: {} })
  paymentSettings: any

  get expirationYearsItems () {
    const arr: any = []
    let currentYear = parseInt(new Date().getFullYear().toString())
    arr.push(currentYear.toString())
    for (let i = 0; i <= 15; i++) {
      arr.push((currentYear++).toString())
    }
    return arr
  }

  data () {
    return {
      states: [
        'AL',
        'AK',
        'AS',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ],
      provinces: [
        'AB',
        'BC',
        'MB',
        'NB',
        'NL',
        'NS',
        'ON',
        'PE',
        'QC',
        'SK',
        'NT',
        'NU',
        'YT'
      ],
      countries: [
        'United States',
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua & Deps',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Central African Rep',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo',
        'Congo {Democratic Rep}',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'East Timor',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland {Republic}',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Korea North',
        'Korea South',
        'Kosovo',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar, {Burma}',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russian Federation',
        'Rwanda',
        'St Kitts & Nevis',
        'St Lucia',
        'Saint Vincent & the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome & Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Trinidad & Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbabwe'
      ],
      submitting: false,
      invalid: false,
      line_selected: null,
      auth: (window as any).Accept,
      credit_card_number: '',
      credit_cvv: '',
      credit_expiration_month: '',
      credit_expiration_year: '',
      credit_first_name: '',
      credit_last_name: '',
      credit_address: '',
      credit_city: '',
      credit_state: '',
      credit_province: '',
      credit_zip: '',
      credit_country: '',
      expirationMonthsItems: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      lineItems: [],
      payment_processor_widget_uuid: ''
    }
  }

  async addTransaction () {
    try {
      this.$data.loading = true
      this.$data.submitting = true
      const payment_settings = this.paymentSettings
      const { definedProperties: { api_login_id = '', public_client_key = '' } = {} } = payment_settings
      const data: any = {
        event_uuid: this.eventUuid,
        participant_uuid: this.participantUuid,
        first_name: this.$data.credit_first_name,
        last_name: this.$data.credit_last_name,
        address: this.$data.credit_address,
        city: this.$data.credit_city,
        zip: this.$data.credit_zip,
        country: this.$data.credit_country,
        credit_card_number: this.$data.credit_card_number,
        credit_cvv: this.$data.credit_cvv,
        credit_expiration_month: this.$data.credit_expiration_month,
        credit_expiration_year: this.$data.credit_expiration_year,
        api_login_id,
        public_client_key,
        payment_type: this.paymentType,
        widget_id: this.widgetUuid,
        transaction_id: this.transactionUuid
      }
      const secureData = {
        cardData: {
          cardNumber: data.credit_card_number,
          month: data.credit_expiration_month,
          year: data.credit_expiration_year,
          cardCode: data.credit_cvv
        },
        authData: {
          clientKey: data.public_client_key,
          apiLoginID: data.api_login_id
        }
      }

      this.$data.auth.dispatchData(secureData, async response => {
        if (response.messages.resultCode === 'Error') {
          let errors = ''
          response.messages.message.forEach(error => {
            errors += error.text
          })
          this.$emit('action', { type: ActionType.FAIL, message: errors })
          Container.get(Notification).error(errors)
        } else {
          const payment_details: any = {
            dataDescriptor: response.opaqueData.dataDescriptor,
            dataValue: response.opaqueData.dataValue,
            expiration_month: data.credit_expiration_month,
            expiration_year: data.credit_expiration_year,
            first_name: data.first_name,
            last_name: data.last_name,
            address: data.address,
            city: data.city,
            zip: data.zip,
            country: data.country
          }
          if (data.country === 'United States') {
            payment_details.state = this.$data.credit_state
          } else if (data.country === 'Canada') {
            payment_details.province = this.$data.credit_province
          }
          const tokenizedData: any = {
            line_items: this.itemsToAdd,
            payment_details,
            form: this.lrordersTransaction ? 'lr_orders' : 'registration',
            make_complete: this.lrordersTransaction ? 1 : 0
          }
          await this.$store.dispatch('payment/createAuthnetTransaction', {
            event_uuid: data.event_uuid,
            widget_id: data.widget_id,
            participant_uuid: data.participant_uuid,
            payment_type: data.payment_type,
            data: tokenizedData
          })
          this.$emit('action', { type: ActionType.SUCCESS, message: 'Transaction successfully added.' })
          Container.get(Notification).success('Transaction successfully added.')
        }
      })
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    } finally {
      this.$data.loading = false
      this.$data.submitting = false
      this.$emit('transaction-attempted')
    }
  }
}
